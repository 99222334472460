import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import { getItem, setItem } from "@/utils/storage.js";
const LandingView = () => import("@/views/LandingView.vue");
const OnboardingView = () => import("@/views/OnboardingView.vue");
const DashboardView = () => import("@/views/DashboardView.vue");
const Four0FourView = () => import("@/views/404.vue");
const LegalView = () => import("@/views/LegalView.vue");
const KycView = () => import("@/views/KycView");

const checkLoginState = (to, from, next) => {
  to.meta.title
    ? (document.title = to.meta.title)
    : (document.title = "Investor App");
  const details = getItem("org_details");
  if (to.name === "EmployeeStocks" && !details) {
    setItem("lastRoute", { name: to.name, query: to.query });
    store.dispatch(
      "showToast",
      {
        description: "Please login or create account to view stock option",
        display: true,
        type: "info",
      },
      { root: true }
    );
  }
  if (to.name === "DealRoomInfo" && !details) {
    setItem("lastRoute", {
      name: to.name,
      params: { id: to.params.id },
      query: to.query,
    });
    store.dispatch(
      "showToast",
      {
        description: "Please login or create account to accept this invite",
        display: true,
        type: "info",
      },
      { root: true }
    );
  }
  if (to.name === "GiftEquity" && !details) {
    setItem("lastRoute", { name: to.name, query: to.query });
    store.dispatch(
      "showToast",
      {
        description: "Please login to claim gift",
        display: true,
        type: "info",
      },
      { root: true }
    );
  }
  if (!details || !details.self_activated || !details || !details.activated)
    return next({ name: "SignIn" });
  if (!details || !details.kyc.terms) {
    store.dispatch("showLegalUpdateModal", true, { root: true });
    next({ name: "LegalTerms" });
    return;
  }
  if (!details) return next({ name: "OnboardingView" });
  if (!details.verified) return next({ name: "Verification" });
  // if (!details.kyc.selfie) return next("/onboarding/upload-selfie");
  // if (!details.kyc.identityDocument) return next("/onboarding/gov-id");
  // if (!details.kyc.address) return next("/onboarding/proof-of-address");
  if (!details.pin_set) return next({ name: "CreatePin" });
  if (details.verified) return next();
};

const checkToken = (to, from, next) => {
  to.meta.title
    ? (document.title = to.meta.title)
    : (document.title = "Investor App");
  const details = getItem("org_details");
  if (!details) return next();
  if (!details.verified) return next();
  if (!details.kyc.selfie) return next();
  if (!details.kyc.identityDocument) return next();
  if (!details.kyc.address) return next();
  if (!details.pin_set) return next();
  if (details.verified) return next({ name: "OverviewDetails" });
  next();
};

const updatePageTitle = (to, from, next) => {
  to.meta.title
    ? (document.title = to.meta.title)
    : (document.title = "Investor App");
  next();
};

const routes = [
  {
    path: "",
    name: "GetStarted",
    beforeEnter: checkToken,
    meta: { title: "Get Started" },
    component: LandingView,
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(
        /* webpackChunkName: "landing" */
        "@/components/general/Logout.vue"
      ),
  },
  {
    path: "/new-password",
    name: "ChangePassword",
    beforeEnter: updatePageTitle,
    meta: { title: "Change Password" },
    component: () =>
      import(
        /* webpackChunkName: "landing" */
        "@/components/general/ChangePassword.vue"
      ),
  },
  {
    path: "/legal-terms",
    name: "LegalTerms",
    beforeEnter: updatePageTitle,
    meta: { title: "Legal Terms" },
    component: LegalView,
  },
  {
    path: "/:pathMatch(.*)",
    name: "Four0FourView",
    beforeEnter: updatePageTitle,
    meta: { title: "404" },
    component: Four0FourView,
  },
  {
    path: "/mobile-bank",
    name: "MobileBank",
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */
        "@/layout/mobile/MobileBank.vue"
      ),
  },
  {
    path: "/mobile-flutterwave",
    name: "MobileFlutterwave",
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */
        "@/layout/mobile/MobileFlutterwave.vue"
      ),
  },
  {
    path: "/mobile-fluidcoins",
    name: "MobileFluidcoins",
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */
        "@/layout/mobile/MobileFluidcoins.vue"
      ),
  },
  {
    path: "/onboarding",
    name: "OnboardingView",
    component: OnboardingView,
    children: [
      {
        path: "",
        name: "DefaultOnboardingView",
        redirect: "/onboarding/signin",
      },
      {
        path: "/onboarding/signin",
        name: "SignIn",
        beforeEnter: checkToken,
        meta: { title: "Signin" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signin/SignIn.vue"
          ),
      },
      {
        path: "/onboarding/create-account",
        name: "CreateAccount",
        beforeEnter: checkToken,
        meta: { title: "Create Account" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/CreateAccount.vue"
          ),
      },
      {
        path: "/onboarding/investor-type",
        name: "InvestorType",
        beforeEnter: checkToken,
        meta: { title: "Investor Type" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/InvestorType.vue"
          ),
      },
      {
        path: "/onboarding/investor-type/confirmation",
        name: "InvestorTypeConfirmation",
        beforeEnter: checkToken,
        meta: { title: "Accredited Investor" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/AccreditedInvestor.vue"
          ),
      },
      {
        path: "/onboarding/about-you",
        name: "AboutYou",
        beforeEnter: checkToken,
        meta: { title: "Personal Details" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/AboutYou.vue"
          ),
      },
      {
        path: "/onboarding/location",
        name: "Location",
        beforeEnter: checkToken,
        meta: { title: "Location" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/Location.vue"
          ),
      },
      {
        path: "/onboarding/verification",
        name: "Verification",
        beforeEnter: checkToken,
        meta: { title: "Email Verification" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/Verification.vue"
          ),
      },
      {
        path: "/onboarding/request-link",
        name: "RequestLink",
        beforeEnter: checkToken,
        meta: { title: "Request Link" },
        component: () =>
          import(
            /* webpackChunkName: "resetPassword" */
            "@/layout/onboarding/resetPassword/RequestLink.vue"
          ),
      },
      {
        path: "/onboarding/reset-password",
        name: "ResetPassword",
        beforeEnter: checkToken,
        meta: { title: "Reset Password" },
        component: () =>
          import(
            /* webpackChunkName: "resetPassword" */
            "@/layout/onboarding/resetPassword/ResetPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/kyc",
    name: "KycView",
    component: KycView,
    children: [
      {
        path: "/kyc/verification",
        name: "KycVerification",
        beforeEnter: checkToken,
        meta: { title: "KYC" },
        component: () =>
          import(
            /* webpackChunkName: "uqudo" */
            "@/layout/onboarding/kyc/KycVerification.vue"
          ),
      },
      {
        path: "/onboarding/upload-selfie",
        name: "UploadSelfie",
        beforeEnter: checkToken,
        meta: { title: "KYC" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/kyc/UploadSelfie.vue"
          ),
      },
      {
        path: "/onboarding/proof-of-address",
        name: "ProofOfAddress",
        beforeEnter: checkToken,
        meta: { title: "KYC" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/kyc/ProofOfAddress.vue"
          ),
      },
      {
        path: "/onboarding/gov-id",
        name: "GovId",
        beforeEnter: checkToken,
        meta: { title: "KYC" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/kyc/GovId.vue"
          ),
      },
      {
        path: "/onboarding/create-pin",
        name: "CreatePin",
        beforeEnter: checkToken,
        meta: { title: "Create Pin" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/CreatePin.vue"
          ),
      },
      {
        path: "/onboarding/confirm-pin",
        name: "ConfirmPin",
        beforeEnter: checkToken,
        meta: { title: "Confirm Pin" },
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */
            "@/layout/onboarding/signup/ConfirmPin.vue"
          ),
      },
    ],
  },
  {
    path: "/dashboard",
    name: "DashboardView",
    component: DashboardView,
    children: [
      {
        path: "",
        name: "DefaultDashboardView",
        redirect: { name: "OverviewDetails" },
      },
      {
        path: "overview",
        name: "OverviewDetails",
        beforeEnter: checkLoginState,
        meta: { title: "Overview" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "@/layout/overview/OverviewDetails.vue"
          ),
      },
      {
        path: "transactions",
        name: "Transactions",
        beforeEnter: checkLoginState,
        meta: { title: "Transactions" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "@/layout/transactions/Transactions.vue"
          ),
      },
      {
        path: "wallet",
        name: "Wallet",
        beforeEnter: checkLoginState,
        meta: { title: "Wallet" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "@/layout/wallet/Wallet.vue"
          ),
      },
      {
        path: "gift-equity",
        name: "GiftEquity",
        beforeEnter: checkLoginState,
        meta: { title: "Gift Equity" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "@/layout/giftEquity/GiftEquity.vue"
          ),
      },
      {
        path: "trade/:option",
        name: "Trade",
        beforeEnter: checkLoginState,
        meta: { title: "Invest" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "@/layout/trade/Trade.vue"
          ),
      },
      {
        path: "order-book",
        name: "OrderBook",
        beforeEnter: checkLoginState,
        meta: { title: "Order Book" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "@/layout/orderbook/OrderBook.vue"
          ),
      },
      {
        path: "employee-stocks",
        name: "EmployeeStocks",
        beforeEnter: checkLoginState,
        meta: { title: "Employee Stock" },
        component: () =>
          import(
            /* webpackChunkName: "esop" */
            "@/layout/Esop/Esops.vue"
          ),
      },
      {
        path: "employee-stocks/pending-esop/:id",
        name: "PendingEsop",
        beforeEnter: checkLoginState,
        component: () =>
          import(
            /* webpackChunkName: "esop" */
            "@/layout/Esop/PendingEsop.vue"
          ),
      },
      {
        path: "employee-stocks/:id",
        name: "EsopOverview",
        beforeEnter: checkLoginState,
        meta: { title: "Employee Stock" },
        component: () =>
          import(
            /* webpackChunkName: "esop" */
            "@/layout/Esop/Esop.vue"
          ),
      },
      {
        path: "trade/:symbol/:id",
        name: "TokenOverview",
        beforeEnter: checkLoginState,
        meta: { title: "Shares" },
        component: () =>
          import(
            /* webpackChunkName: "trade" */
            "@/layout/trade/TokenTrade.vue"
          ),
      },
      {
        path: "trade/orderbook",
        name: "OrderBook",
        beforeEnter: checkLoginState,
        meta: { title: "Order Book" },
        component: () =>
          import(
            /* webpackChunkName: "trade" */
            "@/layout/trade/OrderBook.vue"
          ),
      },
      {
        path: "account",
        name: "Account",
        beforeEnter: checkLoginState,
        component: () =>
          import(
            /* webpackChunkName: "account" */
            "@/layout/accounts/Index.vue"
          ),
        children: [
          {
            path: "",
            name: "DefaultAccount",
            redirect: "/dashboard/account/personal-info",
          },
          {
            path: "personal-info",
            name: "PersonalInfo",
            beforeEnter: updatePageTitle,
            meta: { title: "Personal Info" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/PersonalInfo.vue"
              ),
          },
          {
            path: "identity",
            name: "IdentityVerification",
            beforeEnter: updatePageTitle,
            meta: { title: "ID verification" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/IdentityVerification.vue"
              ),
          },
          {
            path: "bank",
            name: "BankInfo",
            beforeEnter: updatePageTitle,
            meta: { title: "Bank Info" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/Bank.vue"
              ),
          },
          {
            path: "achievements",
            name: "Achievements",
            beforeEnter: updatePageTitle,
            meta: { title: "Achievements" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/Achievements.vue"
              ),
          },
          {
            path: "security",
            name: "Security",
            beforeEnter: updatePageTitle,
            meta: { title: "Security" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/Security.vue"
              ),
          },
          {
            path: "legal",
            name: "Legal",
            beforeEnter: updatePageTitle,
            meta: { title: "Legal" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/Legal.vue"
              ),
          },
          {
            path: "deactivate",
            name: "Deactivate",
            beforeEnter: updatePageTitle,
            meta: { title: "Deactivate Account" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/Deactivate.vue"
              ),
          },
          {
            path: "reset-password",
            name: "PasswordReset",
            beforeEnter: updatePageTitle,
            meta: { title: "Reset Password" },
            component: () =>
              import(
                /* webpackChunkName: "account" */
                "@/layout/accounts/ResetPassword.vue"
              ),
          },
        ],
      },
      {
        path: "deal-room",
        name: "dealRoom",
        beforeEnter: checkLoginState,
        meta: { title: "Private room" },
        component: () =>
          import(
            /* webpackChunkName: "trade" */
            "@/layout/dealroom/Index.vue"
          ),
      },
      {
        path: "deal-room/:id",
        name: "DealRoomInfo",
        beforeEnter: checkLoginState,
        meta: { title: "Private room Info" },
        component: () =>
          import(
            /* webpackChunkName: "trade" */
            "@/layout/dealroom/_id.vue"
          ),
      },
      {
        path: "investor-report",
        name: "InvestorReport",
        beforeEnter: checkLoginState,
        meta: { title: "Investor report" },
        component: () =>
          import(
            /* webpackChunkName: "trade" */
            "@/layout/trade/InvestorReport.vue"
          ),
      },
    ],
  },
  {
    path: "/certificate/:id",
    name: "Certificate",
    meta: { title: "Investor Certificate" },
    component: () =>
      import(
        /* webpackChunkName: "certificate" */
        "@/views/Certificate.vue"
      ),

  },
  {
    path: "/badge/:id",
    name: "Badge",
    meta: { title: "Investor Badge" },
    component: () =>
      import(
        /* webpackChunkName: "certificate" */
        "@/views/Badge.vue"
      ),

  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ el: "#app", top: -10 });
      }, 500);
    });
  },
});

export default router;
