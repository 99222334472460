export const SET_USER_ESOPS = (state, payload) => {
  state.esops = payload;
};

export const SET_ESOP_PAGE_INFO = (state, payload) => {
  state.pageInfo = payload;
};

export const SET_ESOP_INVITE_INFO = (state, payload) => {
  state.inviteInfo = payload;
};

export const SET_ESOP_ORDER = (state, payload) => {
  state.esopOrder = payload;
};

export const SET_ESOP_TOKEN = (state, payload) => {
  state.esopToken = payload;
};
