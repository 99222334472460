<template>
  <Toast />
  <DeactivateModal />
  <LegalUpdateModal />
  <router-view />
</template>

<script>
  import Toast from "@/components/notification/Toast.vue";
  import DeactivateModal from "@/components/notification/DeactivateModal.vue";
  import LegalUpdateModal from "@/components/notification/LegalUpdateModal.vue";

  export default {
    name: "App",

    components: {
      Toast,
      DeactivateModal,
      LegalUpdateModal,
    },
  };
</script>

<style lang="scss">
  .oneroute_widget {
    .widget_container {
      .top_section {
        h3,
        h4 {
          color: #ffffff;
        }
      }
    }
  }

  *,
  #app {
    input,
    button,
    div,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      font-family: "Circular Std";
    }
  }
</style>
