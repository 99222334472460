export default {
  userTokens: null,
  unlimitedUserTokens: null,
  userDealRooms: [],
  privateTokens: null,
  raisingTokens: [],
  raisingPages: 0,
  raisingPage: 0,
  orderBook: [],
  orderBookPages: 0,
  orderBookPage: 0,
  publicTokens: [],
  tokenInfo: {},
  assetInfo: {},
  raisedTokens: [],
  raisedPages: 0,
  raisedPage: 0,
  investorTokenPages: 0,
  investorTokenPage: 0,
  tokenBalance: 0,
  dealRoomInfo: null,
  tokenUpdates: [],
  buyOrderBook: [],
  sellOrderBook: [],
  tokenUpdatePage: 0,
  tokenUpdatePages: 0,
  subscriptionStatus: null,
  subscriptionPlans: null,
  orgInfo: null,
  whyWeListed: {},
  certificateInfo: null,
  badgeInfo: null,
};
