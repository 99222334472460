export const investorDetails = (state) => state.investorDetails;
export const transactions = (state) => state.transactions;
// export const walletBalance = (state) => state.walletBalance;
export const cashWalletBalance = (state) => state.cashWalletBalance;
export const tradingWalletBalance = (state) => state.tradingWalletBalance;
export const availableBalance = (state) => state.availableBalance;
export const equitySalesBalance = (state) => state.equitySalesBalance;
export const totalTransactions = (state) => state.totalTransactions;
export const pages = (state) => state.pages;
export const page = (state) => state.page;
export const exchangeRate = (state) => state.exchangeRate;
export const currencyRate = (state) => state.currencyRate
export const bankDetails = (state) => state.bankDetails;
export const membershipStatus = (state) => state.membershipStatus;
export const walletBalance = (state) => {
  const balances = state.walletBalance;
  const dataArray = balances && Object.entries(balances).map(([value, balance]) => ({
    value,
    currency: `${value.substring(1)}`,
    balance,
  }));
  return dataArray
};

export const minimumWithdrawal = (state) => Number(state.minimumWithdrawal[state.currency.toLowerCase()]);
export const minimumDeposit = (state) => Number(state.minimumDeposit[state.currency.toLowerCase()]);
export const currency = (state) => state.currency;
export const badges = (state) => state.badges;
