export const SET_SIGNIN_LOADING = (state, payload) => {
  state.signinLoading = payload;
};

export const SET_SIGNIN_SUCCESS = (state, payload) => {
  state.signinSuccess = payload;
};

export const SET_CREATE_LOADING = (state, payload) => {
  state.createLoading = payload;
};

export const SET_CREATE_SUCCESS = (state, payload) => {
  state.createSuccess = payload;
};

export const SET_VERIFY_LOADING = (state, payload) => {
  state.verifyLoading = payload;
};

export const SET_VERIFY_SUCCESS = (state, payload) => {
  state.verifySuccess = payload;
};

export const SET_SELFIE_LOADING = (state, payload) => {
  state.selfieLoading = payload;
};

export const SET_SELFIE_SUCCESS = (state, payload) => {
  state.selfieSuccess = payload;
};

export const SET_CREATE_PIN_LOADING = (state, payload) => {
  state.createPinLoading = payload;
};

export const SET_CREATE_PIN_SUCCESS = (state, payload) => {
  state.createPinSuccess = payload;
};

export const SET_VERIFY_PIN_LOADING = (state, payload) => {
  state.verifyPinLoading = payload;
};

export const SET_VERIFY_PIN_SUCCESS = (state, payload) => {
  state.verifyPinSuccess = payload;
};

export const SET_LINK_LOADING = (state, payload) => {
  state.requestLinkLoading = payload;
};

export const SET_LINK_SUCCESS = (state, payload) => {
  state.requestLinkSuccess = payload;
};

export const SET_PASSWORD_LOADING = (state, payload) => {
  state.resetPasswordLoading = payload;
};

export const SET_PASSWORD_SUCCESS = (state, payload) => {
  state.resetPasswordSuccess = payload;
};
