export default {
  toast: {
    description: "",
    display: false,
    type: "",
  },
  deactivateModal: false,
  legalUpdateModal: false,
  loading: false,
  smallLoading: false,
  welcomeModal: false,
  showTourEndModal: false,
  step: 0,
  totalStep: 9,
  carousel: [],
  screenWidth: 0,
};
