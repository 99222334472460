export default {
    investorDetails: {},
    transactions: [],
    walletTransactions: [],
    walletTransactionPage: 0,
    walletTransactionPages: 0,
    notificationCount: 0,
    totalTransactions: 0,
    totalTokens: 0,
    tradingWalletBalance: 0,
    walletBalance: null,
    availableBalance: 0,
    cashWalletBalance: 0,
    exchangeRate: 0,
    tokens: [],
    bankDetails: [],
    searchResult: [],
    pin: 0,
    password: "",
    minimumWithdrawal: 0,
    minimumDeposit: 0,
    membershipStatus: null,
    currency: "",
    fundMethod: "",
    withdrawMethod: "",
    virtualAccount: {},
    orderBook: [],
    totalOrder: 0,
    orderPage: 0,
    orderPages: 0,
    transactionPage: 0,
    transactionPages: 0,
    entityCard: [],
    cardDetails: {},
    code: "",
    codeType: "",
    investorReport: {},
    thePeerWallets: null,
    currencyRate: 0,
    badges: [],
};