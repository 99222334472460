import Tooltip from "@/components/general/Tooltip.vue";
import TourTips from "@/components/general/TourTips.vue";
import Loader from "@/components/notification/Loader.vue";
import SmallLoader from "@/components/notification/SmallLoader.vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { createApp } from "vue";
import "../src/assets/styles/main.scss";
import "../src/assets/styles/tailwind/tailwind.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vClickOutside from "click-outside-vue3"

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG,
  plugins: [new BugsnagPluginVue()],
});
const bugsnagVue = Bugsnag.getPlugin("vue");

const app = createApp(App);


app.config.unwrapInjectedRef = true

app
  .use(store)
  .use(router)
  .use(bugsnagVue)
  .use(vClickOutside)
  .component("Loader", Loader)
  .component("SmallLoader", SmallLoader)
  .component("Tooltip", Tooltip)
  .component("TourTips", TourTips)
  .mount("#app");
