export const SET_GIFT_TOKENS = (state, payload) => {
  state.giftTokens = payload;
};

export const SET_PAGE = (state, payload) => {
  state.page = payload;
};

export const SET_PAGES = (state, payload) => {
  state.pages = payload;
};

export const SET_TOTAL_GIFT_TOKENS = (state, payload) => {
  state.totalGiftTokens = payload;
};
